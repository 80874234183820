import Box from '@mui/material/Box'
import dynamic from 'next/dynamic'
import FavoriteIcon from '@mui/icons-material/Favorite';
import useGlobalSettings from '@components/_hooks/useGlobalSettings';

const ProductCarousel = dynamic(() => import("../ProductComponent/Carousel/ProductCarousel"))
const PageTitle = dynamic(() => import("../Reusables/PageTitle"))

const padding = {
    padding: {
        xs: 0,
        lg: 0           
    },
    height: '100%'
}

const HomeProductCarousel = ({tabs, long_term, data, elevation = 2}) => {

    const {getSetting} = useGlobalSettings();

    return (
        <>
        
            <PageTitle sx={{ color: '#616467' }} component="h3" className="text-center dark-grey uppercase fw-semi-bold">{getSetting('customer_favourites_text','Customer Favourites')}</PageTitle>
            
            <Box sx={padding} >
                <ProductCarousel
                    products={tabs.featured.products}
                    long_term={long_term}
                    productData={data}
                    sectionID={4}
                    elevation={elevation}
                />
            </Box>
        </>
    )
}

export default HomeProductCarousel